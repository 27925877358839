import React from 'react';
import { useTranslation } from 'next-i18next';
import Section from 'src/shared/components/Section';
import styles from './styles.module.scss';
import classnames from 'classnames';

const SECTION_MAX_HEIGHT = 316;
const SECTION_CONTAINER_STYLE = { maxHeight: `${SECTION_MAX_HEIGHT}px`, padding: '0', backgroundImage: "url('/static/images/HeroImage@2x.jpg')" };
const ALT_PARTNER_LOGOS = [
    { class: 'alibaba', image: '/static/images/svg/logo_alibaba.svg' },
    { class: 'pcg', image: '/static/images/svg/logo_pcg.svg' },
];

const PartnershipSection: () => JSX.Element = () => {
    const { t } = useTranslation();

    return (
        <Section className={styles.section} containerStyle={SECTION_CONTAINER_STYLE} fullWidth>
            <div className={styles.layer}>
                <div className={styles.contentContainer}>
                    <div className={styles.title}>{t('home_partnership_title')}</div>
                    <div className={styles.imagesContainer}>
                        {ALT_PARTNER_LOGOS?.map((item, index) => (
                            <img className={classnames(styles[item?.class], styles.image)} src={item?.image} key={index} />
                        ))}
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default PartnershipSection;
