import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';
import RoutePaths from 'src/config/RoutePaths';
import Section from 'src/shared/components/Section';
import RoundedButton from 'src/shared/components/RoundedButton';
import styles from './styles.module.scss';
import { fireGA } from 'src/utils/gaUtils';
import ContentLink from 'src/shared/components/ContentLink';

const SECTION_STYLE = {
    padding: 0,
    backgroundColor: '#051c2d',
    backgroundImage: "url('/static/images/HeroBanner.jpg')",
};

const DescriptionPoint = ({ content }) => (
    <div className={styles.point}>
        <i className={classnames('icon-ico_hero_pointform', styles.bullet)} />
        <span className={styles.pointText}>{content}</span>
    </div>
);

const FootNote = () => {
    const { t } = useTranslation();
    const [piPrefix, piPostfix] = t('home_hero_footnote', { pi: 'pi_placeholder' }).split('pi_placeholder');

    return (
        <div className={styles.footNote}>
            {piPrefix}
            <ContentLink href={RoutePaths.PROFESSIONAL_INVESTOR_NOTICE} ga={{ category: 'marketingQ421', action: 'clickPI', label: 'Land_hero' }}>
                {t('home_professional_investor')}
            </ContentLink>
            {piPostfix}
        </div>
    );
};

const HeroSection = () => {
    const { t } = useTranslation();

    const onSignUpClick = () => {
        fireGA('marketingQ421', 'clickSignUp', 'hero banner');
    };

    return (
        <Section className={styles.section} fullWidth containerStyle={SECTION_STYLE}>
            <div className={styles.layer}>
                <div className={styles.container}>
                    <div className={styles.slogan}>{t('home_hero_title')}</div>
                    <div className={styles.subTitle}>{t('home_hero_subtitle')}</div>
                    <div className={styles.description}>
                        <DescriptionPoint content={t('home_hero_description_1')} />
                        <DescriptionPoint content={t('home_hero_description_2')} />
                    </div>
                    <RoundedButton
                        data-test="homeSignUpButton"
                        className={styles.signUpButton}
                        type="primary-on-dark"
                        href={RoutePaths.REGISTER}
                        onClick={onSignUpClick}
                    >
                        {t('home_sign_up_button')}
                    </RoundedButton>
                    <FootNote />
                </div>
            </div>
        </Section>
    );
};

export default HeroSection;
