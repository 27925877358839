import React, { useState } from 'react';
import MarketingSection from 'src/components/MarketingSection';
import styles from './styles.module.scss';
import gridStyles from 'src/shared/theme/grid.module.scss';
import { useTranslation } from 'next-i18next';
import Image from 'src/shared/components/Image';
import FaqItem from './components/FaqItem';
import { fireGA } from 'src/utils/gaUtils';
import { useRouter } from 'src/shared/hooks';
import useFAQ from './hooks/useFAQ';

const FaqSection = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const faq = useFAQ(router.locale);

    const [faqVisibilities, setFaqVisibilities] = useState(faq.map(() => false));

    const onOpen = (i) => {
        const visibilities = faq.map(() => false);
        visibilities[i] = true;
        setFaqVisibilities(visibilities);
        fireGA('marketingQ421', 'clickFaq', `Q${i + 1}`);
    };

    return (
        <MarketingSection containerClassName={styles.faqSection} className={styles.inner}>
            <p className={styles.title}>{t('faq_about_altive')}</p>
            <div className={`${gridStyles.container}`}>
                <div className={gridStyles.xl6} />
                <div className={`${gridStyles.xl6} ${gridStyles.lg12} ${styles.faqList}`}>
                    {faq.map((x, i) => (
                        <FaqItem key={i} {...x} onOpen={() => onOpen(i)} isOpened={faqVisibilities[i]} />
                    ))}
                </div>
            </div>
            <div className={styles.image}>
                <Image multiLang className={styles.architectureImage} animation="fade" src="/static/images/img_faq_mockup@2x.png" />
            </div>
        </MarketingSection>
    );
};

export default FaqSection;
