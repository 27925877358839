import React, { useEffect, useState } from 'react';
import useRouter from 'src/shared/hooks/useRouter';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';
import withConnect from 'src/shared/utils/withConnect';
import { compose } from 'redux';
import LayoutWrapper from 'src/shared/components/LayoutWrapper';
import globalActions from 'src/shared/store/global/actions';
import SubscriptionAds from 'src/components/SubscriptionAds';
import TraditionalVsAlternativeTableSection from 'src/components/TraditionalVsAlternativeTableSection';
import FaqSection from 'src/components/FaqSection';
import GaService from 'src/services/GaService';

import HeroSection from './components/HeroSection';
import ReasonsAndContactSection from './components/ReasonsAndContactSection';
import InsightSection from './components/InsightSection';
import PartnershipsSection from './components/PartnershipSection';
import ExploreSection from './components/ExploreSection';
import QuoteSection from './components/QuoteSection';

type HomeProps = {
    preference: any;
};

const Home = (props: HomeProps) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const { preference } = props;
    const [triggerSubscriptionAds, setTriggerSubscriptionAds] = useState(false);

    useEffect(() => {
        GaService.pageView(router.route);
        // @HOTFIX temp disable
        // window.addEventListener('scroll', handleScroll);
        // return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const showSubscriptionAds = (delay) => {
        setTimeout(() => {
            if (preference && preference.subscribeNewsletter) return;
            dispatch(
                globalActions.showModal({
                    width: '700px',
                    style: { backgroundImage: "url('/static/images/Altive_web_popup_bg.png')", backgroundColor: '#293B4B', backgroundSize: 'cover' },
                    children: <SubscriptionAds />,
                })
            );
        }, delay);
    };

    const handleScroll = () => {
        if (triggerSubscriptionAds) {
            return;
        }
        showSubscriptionAds(3000);
        setTriggerSubscriptionAds(true);
    };

    return (
        <LayoutWrapper className={styles.container} headerProps={{ type: 'transparent', fixed: true }}>
            <HeroSection />
            <PartnershipsSection />
            <TraditionalVsAlternativeTableSection />
            <ReasonsAndContactSection />
            <ExploreSection />
            <QuoteSection />
            <InsightSection />
            <FaqSection />
        </LayoutWrapper>
    );
};

const mapStateToProps = (state) => {
    return { preference: state.preference };
};

export default compose(withConnect(mapStateToProps))(Home);
