import React from 'react';
import { useTranslation } from 'next-i18next';

import GaService from 'src/services/GaService';
import GaCategory from 'src/config/GaCategory';
import GaAction from 'src/config/GaAction';
import RoundedButton from 'src/shared/components/RoundedButton';

import { ExploreItemType } from '../../hooks/useExploreItems';
import styles from './styles.module.scss';
import { fireGA } from 'src/utils/gaUtils';

interface ExploreCardProps extends ExploreItemType {
    size: {
        width: number;
        height: number;
    };
}

const ExploreCard: (exploreItem: ExploreCardProps) => JSX.Element = ({
    size: { width, height },
    title,
    description,
    href,
    backgroundImage,
    gaLabel,
}) => {
    const { t } = useTranslation();

    const onLearnMoreClick = () => {
        fireGA('marketingQ421', 'clickLearnMore', gaLabel);
    };

    return (
        <div className={styles.container} style={{ flexBasis: width, height }}>
            <div className={styles.background} style={{ backgroundImage: `url('${backgroundImage}')` }} />
            <div className={styles.cardContent}>
                <div className={styles.cardTitle}>{title}</div>
                <div className={styles.cardDescription}>{description}</div>
                <RoundedButton
                    data-test="learnMoreButton"
                    className={styles.cardButton}
                    type="secondary-on-dark"
                    onClick={onLearnMoreClick}
                    href={href}
                >
                    {t('learn_more_button')}
                </RoundedButton>
            </div>
        </div>
    );
};

export default ExploreCard;
