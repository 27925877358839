/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import styles from './styles.module.scss';
import Link from 'src/shared/components/Link';
import { fireGA } from 'src/utils/gaUtils';
import GaCategory from 'src/config/GaCategory';
import GaAction from 'src/config/GaAction';

interface Props {
    href: string;
    children?: string;
    ga?: {
        category: keyof typeof GaCategory;
        action: keyof typeof GaAction;
        label?: string;
    };
}

const ContentLink = (props: Props) => {
    const clickLink = () => {
        if (props.ga) {
            fireGA(props.ga.category, props.ga.action, props.ga.label || '');
        }
    };

    return (
        <Link href={props.href}>
            <a className={styles.link} target="_blank" onClick={clickLink}>
                {props.children}
            </a>
        </Link>
    );
};

ContentLink.defaultProps = {
    children: '',
    ga: null,
};

export default ContentLink;
