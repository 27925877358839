import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import ReactPlaceholder from 'react-placeholder/lib';

import RoutePaths from 'src/config/RoutePaths';
import ArticleAPI from 'src/services/api/ArticleAPI';
import ArticlePostModel from 'src/types/article/ArticlePostModel';
import Section from 'src/shared/components/Section';
import RoundedButton from 'src/shared/components/RoundedButton';

import HighlightedArticleCard from './components/HighlightedArticleCard';
import styles from './styles.module.scss';
import { fireGA } from 'src/utils/gaUtils';
import Language from 'src/config/Language';

const SECTION_CONTAINER_STYLE = { height: '100%', padding: 0 };
const FILTER_CATEGORY = 'insights';

const InsightSection = () => {
    const recordsPerSet = 10;
    const { t, i18n } = useTranslation();
    const [latestNewsList, setLatestNewsList] = useState<Array<ArticlePostModel>>([]);
    const [loading, setLoading] = useState(false);

    const language = Language[i18n.language].mediaLocale;

    const onShowMoreClick = () => {
        fireGA('marketingQ421', 'clickInsights', 'Land_show all');
    };

    const loadArticles = async () => {
        setLoading(true);
        const params = { page: 1, limit: recordsPerSet, category: FILTER_CATEGORY, language };
        const result = await ArticleAPI.getArticleList(params);
        if (result.status === 1) {
            setLatestNewsList(result.data.hits.slice(0, 3));
        }
        setLoading(false);
    };

    useEffect(() => {
        loadArticles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    return (
        <Section className={styles.section} containerStyle={SECTION_CONTAINER_STYLE} fullWidth>
            <div className={styles.layer}>
                <div className={styles.sectionTitle}>{t('home_insight_title')}</div>
                <div className={styles.sectionDescription}>{t('home_insight_description')}</div>
                <ReactPlaceholder showLoadingAnimation type="text" rows={10} ready={!loading}>
                    <div className={styles.contentContainer}>
                        {latestNewsList.length > 0 &&
                            latestNewsList.map((i: ArticlePostModel) => <HighlightedArticleCard key={i.articleId} highlightedArticle={i} />)}
                    </div>
                </ReactPlaceholder>
                <RoundedButton className={styles.showAllBtn} type="tertiary-on-light" href={RoutePaths.INSIGHTS} onClick={onShowMoreClick}>
                    {t('home_show_all_button')}
                </RoundedButton>
            </div>
        </Section>
    );
};

export default InsightSection;
