import React from 'react';
import withConnect from 'src/shared/utils/withConnect';
import styles from './styles.module.scss';
import { Form } from 'react-apoidea';
import Link from 'src/shared/components/Link';
import RoutePaths from 'src/config/RoutePaths';
import globalActions from 'src/shared/store/global/actions';
import ErrorService from 'src/shared/services/ErrorService';
import AccountAPI from 'src/shared/services/api/AccountAPI';
import Message from 'src/shared/config/Message';
import { useTranslation } from 'next-i18next';
import { preferenceActions } from 'src/store/preference';
import GaService from 'src/services/GaService';
import GaCategory from 'src/config/GaCategory';
import GaAction from 'src/config/GaAction';

const SubscriptionAds = (props) => {
    const { t, i18n } = useTranslation();
    const { dispatch } = props;

    const fields = [
        {
            controlId: 'name',
            label: t('form_name'),
            type: 'text',
            validate: {
                required: true,
                message: t(Message.FIELD_REQUIRED),
            },
        },
        {
            controlId: 'phoneCountryCode',
            label: t('country_code'),
            type: {
                name: 'phoneNumber',
                countryCodeOnly: true,
                locale: i18n.language,
            },
            value: '852',
            col: {
                lg: 6,
            },
            validate: {
                required: true,
                message: t(Message.FIELD_REQUIRED),
            },
        },
        {
            controlId: 'phone',
            label: t('form_mobile'),
            type: {
                name: 'phoneNumber',
                phoneNumberOnly: true,
                locale: i18n.language,
            },
            col: {
                lg: 6,
            },
            validate: {
                required: true,
                message: t(Message.FIELD_REQUIRED),
            },
        },
        {
            controlId: 'email',
            label: t('login_email'),
            type: 'email',
            validate: {
                email: true,
                required: true,
                message: t(Message.EMAIL_REQUIRED),
            },
        },
        {
            controlId: 'isProfessional',
            type: 'checkbox',
            label: (
                <p>
                    {t('checkbox_statement_1_1')}{' '}
                    <Link href={RoutePaths.PROFESSIONAL_INVESTOR_NOTICE}>
                        <a className={styles.agreement} target="_blank">
                            {t('checkbox_statement_1_2')}
                        </a>
                    </Link>
                    {t('checkbox_statement_2_5')}
                </p>
            ),
            validate: {
                required: true,
                message: t(Message.FIELD_REQUIRED),
            },
            className: styles.checkbox,
        },
    ];

    const onSubmit = async ({ formData }) => {
        GaService.callEvent(GaCategory.marketing, GaAction.clickSubscribeButtonOnSubscriptionPopup, 'subscription popup');
        const data = formData.isProfessional ? formData : { ...formData, isProfessional: false };
        const resp = await AccountAPI.subscribeNewsletter(data);

        if (resp.status) {
            await dispatch(preferenceActions.setPreference({ subscribeNewsletter: true }));
            dispatch(
                globalActions.showAlertDialog({
                    title: t('dialog_title_notice'),
                    text: t('success_sub_newsletter'),
                    onConfirm: () => {
                        dispatch(globalActions.dismissModal());
                    },
                })
            );
        } else {
            const error = ErrorService.getError(resp);
            dispatch(globalActions.handleErrorDialog(error));
        }
    };

    return (
        <div className={styles.container}>
            <p className={styles.title}>{t('dialog_title_subscription')}</p>
            <Form className={styles.form} fields={fields} onSubmit={onSubmit} button={t('submit_button')} />
        </div>
    );
};

SubscriptionAds.defaultProps = {};

SubscriptionAds.propTypes = {};

export default withConnect()(SubscriptionAds);
