import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'next-i18next';

import RoutePaths from 'src/config/RoutePaths';
import ContentLink from 'src/shared/components/ContentLink';
import useCompanyInfo from 'src/hooks/useCompanyInfo';

export default function useFAQ(locale: string) {
    const { t } = useTranslation();
    const { csEmailHref, getPhoneHref } = useCompanyInfo();

    return useMemo(() => {
        switch (locale) {
            case 'au':
                return [
                    {
                        title: t('faq_au_question1'),
                        description: t('faq_au_anwser1'),
                    },
                    {
                        title: t('faq_au_question2'),
                        description: t('faq_au_anwser2'),
                    },
                    {
                        title: t('faq_au_question3'),
                        description: t('faq_au_anwser3'),
                    },
                    {
                        title: t('faq_au_question4'),
                        description: t('faq_au_anwser4'),
                    },
                    {
                        title: t('faq_au_question5'),
                        description: t('faq_au_anwser5'),
                    },
                    {
                        title: t('faq_au_question6'),
                        description: t('faq_au_anwser6'),
                    },
                    {
                        title: t('faq_au_question7'),
                        description: t('faq_au_anwser7'),
                    },
                    {
                        title: t('faq_au_question8'),
                        description: t('faq_au_anwser8'),
                    },
                ];
            default:
                return [
                    {
                        title: t('platform_faq1_question'),
                        description: (
                            <Trans
                                i18nKey="platform_faq1_answer"
                                components={[<ContentLink key={0} href={RoutePaths.PROFESSIONAL_INVESTOR_NOTICE} />]}
                            />
                        ),
                    },
                    {
                        title: t('platform_faq2_question'),
                        description: <Trans i18nKey="platform_faq2_answer" />,
                    },
                    {
                        title: t('platform_faq3_question'),
                        description: <Trans i18nKey="platform_faq3_answer" />,
                    },
                    {
                        title: t('platform_faq4_question'),
                        description: <Trans i18nKey="platform_faq4_answer" components={[<ContentLink key={0} href={RoutePaths.REGISTER} />]} />,
                    },
                    {
                        title: t('platform_faq5_question'),
                        description: <Trans i18nKey="platform_faq5_answer" />,
                    },
                    {
                        title: t('platform_faq6_question'),
                        description: <Trans i18nKey="platform_faq6_answer" />,
                    },
                ];
        }
    }, [locale, t]);
}
