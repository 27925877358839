import React from 'react';
import { useTranslation } from 'next-i18next';

import { isFirefox } from 'src/shared/utils/browserUtils';
import Section from 'src/shared/components/Section';
import classnames from 'classnames';
import styles from './styles.module.scss';
import PlainButton from 'src/shared/components/PlainButton';

const SECTION_STYLE = {
    padding: 0,
    backgroundColor: styles.themeColor,
};

const ANNUAL_REVIEW_LINK =
    'https://www.mckinsey.com/industries/private-equity-and-principal-investors/our-insights/mckinseys-private-markets-annual-review';

interface authorCardProps {
    name: string;
    position: string;
    imagePath: string;
}

const AuthorCard = (props: authorCardProps) => {
    const { name, position, imagePath } = props;
    return (
        <div className={styles.cardContainer}>
            <div className={styles.authorInfo}>
                <span className={styles.authorName}>{name}</span>
                <span className={styles.authorPosition}>{position}</span>
            </div>
            <img className={styles.authorAvatar} src={imagePath} />
        </div>
    );
};

const QuotesSection = () => {
    const { t } = useTranslation();

    const onClickFootnote = () => window.open(ANNUAL_REVIEW_LINK, '_blank');

    return (
        <Section className={styles.section} fullWidth containerStyle={SECTION_STYLE}>
            <div className={styles.layer}>
                <div className={styles.quoteContainer}>
                    <span className={styles.quoteText}>{t('home_quote_text')}</span>
                    <div className={styles.remarks}>
                        <AuthorCard
                            name={t('home_quote_author')}
                            position={t('home_quote_author_position')}
                            imagePath="/static/images/richard_avatar.jpg"
                        />
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default QuotesSection;
