import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styles from './styles.module.scss';
import BaseIcon from 'src/shared/components/BaseIcon';
import PlainButton from 'src/shared/components/PlainButton';

interface Props {
    title: string;
    description: string | React.ReactNode;
    isOpened?: boolean;
    onOpen: () => void;
}

const variants = {
    opened: { height: 'auto' },
    closed: { height: 0 },
};

const variantsInner = {
    opened: { opacity: 1 },
    closed: { opacity: 0 },
};

const FaqItem = (props: Props) => {
    const [stage, setStage] = useState<'opened' | 'closed'>('closed');

    useEffect(() => {
        if (props.isOpened && stage === 'closed') {
            setStage('opened');
        } else if (!props.isOpened && stage === 'opened') {
            setStage('closed');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpened]);

    const onClick = () => {
        if (stage === 'opened') {
            setStage('closed');
        } else {
            setStage('opened');
            props.onOpen();
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <PlainButton className={styles.button} clear onClick={onClick}>
                    <p className={styles.title}>{props.title}</p>
                    <BaseIcon type={stage === 'opened' ? 'icon-btn_collapse' : 'icon-btn_expand'} fontSize="23px" />
                </PlainButton>
            </div>
            <motion.div className={styles.item} initial="closed" animate={stage} variants={variants}>
                <motion.div initial="closed" animate={stage} variants={variantsInner}>
                    <p className={styles.content}>{props.description}</p>
                </motion.div>
            </motion.div>
        </div>
    );
};

FaqItem.defaultProps = {
    isOpened: false,
};

export default FaqItem;
