import React from 'react';
import Section from 'src/shared/components/Section';
import ContactSalesForm from 'src/components/ContactSalesForm';
import useReasons from './hooks/useReasons';
import { ReasonType } from './types';
import styles from './styles.module.scss';
import RoundedButton from 'src/shared/components/RoundedButton';
import { useTranslation } from 'next-i18next';
import RoutePaths from 'src/config/RoutePaths';
import { fireGA } from 'src/utils/gaUtils';

const ReasonItem = ({ icon, title }: ReasonType) => {
    return (
        <div className={styles.reasonItemContainer}>
            <div className={styles.reasonItemIconContainer}>
                <i className={`${styles.icon} icon-${icon}`} />
            </div>
            <div className={styles.reasonItemContent}>
                <div className={styles.reasonItemTitle}>{title}</div>
            </div>
        </div>
    );
};

const Description = () => {
    const reasons = useReasons();
    const { t } = useTranslation();

    const onGetStartedClick = () => {
        fireGA('marketingQ421', 'clickHowItWorks', 'land_hero3 banner');
    };

    return (
        <div className={styles.descriptionContainer}>
            <div className={styles.descriptionTitleContainer}>
                <div className={styles.descriptionTitle}>{t('home_reasons_title')}</div>
            </div>
            <div className={styles.descriptionContentContainer}>
                {reasons.map((item, idx) => (
                    <ReasonItem key={`content-item-${idx}`} {...item} />
                ))}
            </div>
            <div className={styles.descriptionRemarks}>
                <RoundedButton className={styles.button} type="white" href={RoutePaths.HOW_IT_WORKS} onClick={onGetStartedClick}>
                    {t('home_reasons_get_start_button')}
                </RoundedButton>
            </div>
        </div>
    );
};

const ReasonsAndContactSection = () => {
    const { t } = useTranslation();
    const PRESET_SECTION_STYLE = { height: '100%', padding: 0, backgroundColor: styles.themeColor, overflow: 'visible' };

    const onFireGa = () => {
        fireGA('marketingQ421', 'clickContactUs', 'land_hero3');
    };

    return (
        <Section className={styles.section} fullWidth containerStyle={PRESET_SECTION_STYLE}>
            <div className={styles.layer}>
                <Description />
                <ContactSalesForm
                    className={styles.contactSalesForm}
                    titleClassName={styles.formTitle}
                    title={t('home_contact_title')}
                    onFireGa={onFireGa}
                />
            </div>
        </Section>
    );
};

export default ReasonsAndContactSection;
