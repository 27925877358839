import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';

import useResize from 'src/shared/hooks/useResize';
import Section from 'src/shared/components/Section';
import useExploreItems from './hooks/useExploreItems';

import ExploreCard from './components/ExploreCard';
import styles from './styles.module.scss';
import { useGesture } from 'react-use-gesture';

const CARD_ASPECT_RATIO = 360 / 702;
const SECTION_MIN_HEIGHT = 500;
const SECTION_CONTAINER_STYLE = { minHeight: `${SECTION_MIN_HEIGHT}px`, padding: '0', backgroundImage: "url('/static/images/HeroImage@2x.jpg')" };

const ExploreSection: () => JSX.Element = () => {
    const { t } = useTranslation();
    const exploreItems = useExploreItems();
    const {
        screenSize: { width, height },
    } = useResize();
    const [cardSize, setCardSize] = useState({ height: 0, width: 0 });

    const scrollRef = useRef<any>();
    const scrollingPosition = useRef<number>(0);

    const getMaxHeight = (screenWidth) => {
        let maxHeight = 702;
        if (screenWidth <= 768) {
            maxHeight = 500;
        } else if (screenWidth <= 1024) {
            maxHeight = 600;
        }
        return maxHeight;
    };

    const bindGesture = useGesture({
        onDragStart: () => {
            if (!scrollRef.current) {
                return;
            }
            scrollingPosition.current = scrollRef.current.scrollLeft;
        },
        onDrag: ({ movement: [mx] }) => {
            if (!scrollRef.current) {
                return;
            }
            scrollRef.current.scrollTo({ top: 0, left: scrollingPosition.current - mx });
        },
    });

    // Calculate the actual card size. If the screen is not wide enough to show all the cards, then x.5 cards should be shown.
    useEffect(() => {
        if (!width || !height) {
            return;
        }

        const maxHeight = getMaxHeight(width);
        const perfectCardHeight = Math.min(maxHeight, Math.max(height * 0.65, SECTION_MIN_HEIGHT));
        const perfectCardWidth = perfectCardHeight * CARD_ASPECT_RATIO;
        const noOfFullSizeCard = Math.floor(width / perfectCardWidth);
        const actualCardWidth = Math.floor(width / (noOfFullSizeCard + 0.5));
        const actualCardHeight = Math.floor(actualCardWidth / CARD_ASPECT_RATIO);
        setCardSize({ width: actualCardWidth, height: actualCardHeight });
    }, [width, height]);

    return (
        <Section className={styles.section} containerStyle={SECTION_CONTAINER_STYLE} fullWidth>
            <div className={styles.layer}>
                <div className={styles.contentContainer}>
                    <div className={styles.title}>{t('home_explore_title')}</div>
                    <div className={styles.content}>{t('home_explore_subtitle')}</div>
                    <div className={styles.scroll} ref={scrollRef} {...bindGesture()}>
                        {exploreItems.map((item, idx) => (
                            <ExploreCard key={`explore-item-${idx}`} {...item} size={cardSize} />
                        ))}
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default ExploreSection;
