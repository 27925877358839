import React from 'react';
import { useTranslation } from 'next-i18next';
import MarketingSection from 'src/components/MarketingSection';
import styles from './styles.module.scss';
import ComparisonTable from 'src/components/ComparisonTable';
import ResponsiveComparisonTable from 'src/components/ResponsiveComparisonTable';

const TraditionalVsAlternativeTableSection = () => {
    const { t } = useTranslation();

    const contentData: any = {
        titles: [
            { icon: 'icon-ico_badge', text: t('asset_classes') },
            { icon: 'icon-ico_global', text: t('liquidity') },
            { icon: 'icon-ico_reason2', text: t('correlation') },
            { icon: 'icon-ico_platforms', text: t('volatility') },
        ],
        card1: {
            header: {
                title: t('traditional_investment'),
                titleSize: 'small',
            },
            items: [
                t('traditional_investment_asset_classes'),
                t('traditional_investment_liquidity'),
                t('traditional_investment_correlation'),
                t('traditional_investment_volatility'),
            ],
        },
        card2: {
            header: {
                title: t('alternative_investments'),
                titleSize: 'small',
            },
            items: [
                t('alternative_investments_asset_classes'),
                t('alternative_investments_liquidity'),
                t('alternative_investments_correlation'),
                t('alternative_investments_volatility'),
            ],
        },
    };

    return (
        <MarketingSection containerClassName={styles.container} className={styles.inner}>
            <p className={styles.title}>{t('private_market_comparison_title')}</p>
            <ComparisonTable {...contentData} />
            <ResponsiveComparisonTable {...contentData} />
        </MarketingSection>
    );
};

export default TraditionalVsAlternativeTableSection;
