import React from 'react';
import Image from 'src/shared/components/Image';
import Link from 'src/shared/components/Link';
import styles from './styles.module.scss';
import ArticlePostModel from 'src/types/article/ArticlePostModel';
import { useTranslation } from 'next-i18next';
import BaseText from 'src/shared/components/BaseText';
import common from 'src/shared/utils/common';
import { fireGA } from 'src/utils/gaUtils';
import RoutePaths from 'src/config/RoutePaths';
import useArticleInfo from 'src/hooks/useArticleInfo';

interface HighlightedArticleProps {
    highlightedArticle: ArticlePostModel;
}

const HighlightedArticleCard = (props: HighlightedArticleProps) => {
    const { highlightedArticle } = props;
    const { category } = highlightedArticle;
    const { t } = useTranslation();
    const { publishedAtText } = useArticleInfo(highlightedArticle);

    let link = '';
    if (highlightedArticle.category) {
        const route = common.getAsPath(RoutePaths.NEWS_DETAIL, {
            category: highlightedArticle.category.categoryUrl,
            articleUrl: highlightedArticle.articleUrl,
        });
        link = route.as;
    }

    const typeText = category.categoryUrl === 'news' ? t('news') : t('insight');

    const onArticleClick = () => {
        fireGA('marketingQ421', 'clickHighlightArticle', 'home insights and news section');
    };

    return (
        <Link href={link}>
            <div className={styles.articleCardContainer} onClick={onArticleClick} aria-hidden="true">
                <Image
                    src={highlightedArticle.coverImage}
                    className={styles.image}
                    alt={highlightedArticle.coverImageAltTag}
                    dataTest="coverImage"
                    renderEmptyImg={() => <img className={styles.empty_image} src="/static/images/article_thumbnail_default@2x.jpg" />}
                />
                <div className={`${styles.cardDetails} ${styles.content}`}>
                    <span className={styles.categoryTag}>{typeText}</span>
                    <BaseText className={styles.newsTitle} dataTest="title">
                        {highlightedArticle.title}
                    </BaseText>
                    <div className={styles.footer}>
                        <BaseText className={styles.author} dataTest="author">
                            {t('authorized_by') + ` ${highlightedArticle.author}`}
                        </BaseText>
                        <BaseText className={styles.date} dataTest="date">
                            {publishedAtText}
                        </BaseText>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default HighlightedArticleCard;
