import { useTranslation } from 'next-i18next';

export type ExploreItemType = {
    title: string;
    description: string;
    href: string;
    backgroundImage: string;
    gaLabel: string;
};

const useExploreItems: () => ExploreItemType[] = () => {
    const { t } = useTranslation();

    return [
        {
            title: t('explore_pre_ipo_title'),
            description: t('explore_pre_ipo_description'),
            href: 'https://www.altive.com/media/insights/understand-pre-ipo-placement',
            backgroundImage: '/static/images/explorePreIpo.jpg',
            gaLabel: 'Pre-IPO Placement',
        },
        {
            title: t('explore_unicorn_title'),
            description: t('explore_unicorn_description'),
            href: 'https://www.altive.com/media/insights/understand-unicorn-shares',
            backgroundImage: '/static/images/exploreUnicornShares.jpg',
            gaLabel: 'Private Unicorn Shares',
        },
        {
            title: t('explore_private_fund_title'),
            description: t('explore_private_fund_description'),
            href: 'https://www.altive.com/media/insights/understand-private-equity-fund',
            backgroundImage: '/static/images/exploreEquityFund.jpg',
            gaLabel: 'Private Equity Fund',
        },
        {
            title: t('explore_hudge_fund_title'),
            description: t('explore_hudge_fund_description'),
            href: 'https://www.altive.com/media/insights/understand-hedge-fund',
            backgroundImage: '/static/images/exploreHedgeFund.jpg',
            gaLabel: 'Hedge Fund',
        },
        {
            title: t('explore_credit_title'),
            description: t('explore_credit_description'),
            href: 'https://www.altive.com/media/insights/understand-private-debt',
            backgroundImage: '/static/images/explorePrivateDept.jpg',
            gaLabel: 'Private Debt',
        },
        {
            title: t('explore_assets_title'),
            description: t('explore_assets_description'),
            href: 'https://www.altive.com/media/insights/understand-real-assets',
            backgroundImage: '/static/images/exploreRealAssets.jpg',
            gaLabel: 'Real Assets',
        },
        {
            title: t('explore_venture_fund_title'),
            description: t('explore_venture_fund_description'),
            href: 'https://www.altive.com/en/media/insights/understand-venture-capital-funds',
            backgroundImage: '/static/images/exploreVentureCapitalFund.jpg',
            gaLabel: 'Venture Capital Funds',
        },
    ];
};

export default useExploreItems;
