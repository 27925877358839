import DocusignEvent from 'src/shared/config/DocusignEvent';
import Home from 'src/pages/Home';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import withDisclaimer from 'src/utils/withDisclaimer';

export const getServerSideProps = async (context) => {
    const { req, res, locale, query } = context;
    if (req) {
        // Handle docusign events
        const docusignEvents = Object.values(DocusignEvent);
        if (query && query.event && docusignEvents.includes(query.event)) {
            res.end('loading...');
        }
    }
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common', 'disclaimer'])),
        },
    };
};

export default withDisclaimer()(Home);
