import { useTranslation } from 'next-i18next';
import { ReasonType } from '../types';

const useReasons: () => ReasonType[] = () => {
    const { t } = useTranslation();

    // @TODO add title and description to excel
    return [
        {
            title: t('home_reasons_lower_entry_title'),
            icon: 'ico_reason1',
        },
        {
            title: t('home_reasons_access_title'),
            icon: 'ico_reason2',
        },
        {
            title: t('home_reasons_handpicked_title'),
            icon: 'ico_reason3',
        },
        {
            title: t('home_reasons_return_title'),
            icon: 'ico_chart',
        },
    ];
};

export default useReasons;
